import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import {
  Container,
  ContainerColumn1,
  ContainerGrid,
  ContainerTitle,
  ContainerWrapper,
  HomeButton,
  HomeButtonArea,
  TextArea,
  TextParagraph,
  TextTitle,
} from '../components/ConditionsElem';
import { PrivacyPolicyEn } from '../components/LegalData/Data';

function GDPRPage() {
  return (
    <Container id="gdprp">
      <HomeButtonArea>
        <FaArrowLeft />
        <HomeButton to="/">Back to home page</HomeButton>
      </HomeButtonArea>
      <ContainerWrapper>
        <ContainerTitle>privacy policy</ContainerTitle>
        <ContainerGrid>
          <ContainerColumn1>
            <TextArea>
              <i>[EN]</i>
              <TextParagraph>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi voluptate quo, consectetur adipisci voluptas pariatur
                quaerat. Sed quaerat itaque praesentium! Laudantium consequatur,
                est autem voluptatum excepturi voluptatem asperiores at natus!
                Eligendi voluptate quo, consectetur adipisci voluptas pariatur
                quaerat. Sed quaerat itaque praesentium! Laudantium consequatur,
                est autem voluptatum excepturi voluptatem asperiores at natus!
              </TextParagraph>
              {PrivacyPolicyEn.map((item) => (
                <>
                  <TextTitle>{item.title}</TextTitle>
                  <TextParagraph>{item.text}</TextParagraph>
                </>
              ))}
            </TextArea>
          </ContainerColumn1>
        </ContainerGrid>
      </ContainerWrapper>
    </Container>
  );
}

export default GDPRPage;
