import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import CookiesPage from './pages/CookiesPage';
import GDPRPage from './pages/GDPRPage';
import Sidebar from './components/Sidebar/Sidebar';
import TcsPage from './pages/TcsPage';
import ScrollToTop from './ScrollToTop';
import CookieConsent from 'react-cookie-consent';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Routes>
        <Route path="/termuse" element={<TermsPage />} />
        <Route path="/termservices" element={<TcsPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/gdpr" element={<GDPRPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
      <CookieConsent
        debug={true}
        style={{
          background: '#000',
          height: '80px',
          textAlign: 'center',
          fontSize: '20px',
        }}
        enableDeclineButton
        buttonStyle={{
          color: '#fff',
          background: '#0261a0',
          padding: '10px 20px',
        }}
        declineButtonStyle={{
          color: '#fff',
          padding: '10px 20px',
        }}
        buttonText="I ACCEPT"
        declineButtonText="I DECLINE"
        onAccept={() => {
          console.log('User accepted the cookie policy');
        }}
        onDecline={() => {
          alert('By declining, browsing Cardinal Shipping is not allowed');
          window.location.href = 'https://google.com';
        }}
      >
        Our website is using cookies in order to make the user experience
        better. See our{' '}
        <Link className="consent" to="/gdpr">
          privacy policy
        </Link>{' '}
        for more informations.
      </CookieConsent>
      <Footer />
    </Router>
  );
}

export default App;
