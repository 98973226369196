import React from 'react';
import styled from 'styled-components';
import { Paragraph, Title } from '../SectionsElem';

export const LegalContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

export const MissionContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;
function LegalMs() {
  return (
    <>
      <LegalContainer id="legal">
        <Title whiteBg={true}>Legal</Title>
        <hr />
        <Paragraph whiteBg={true}>
          <strong>Lorem, ipsum dolor</strong> sit amet consectetur adipisicing
          elit. Eligendi voluptate quo, consectetur adipisci voluptas pariatur
          quaerat. Sed quaerat itaque praesentium! Laudantium consequatur, est
          autem voluptatum excepturi voluptatem asperiores at natus!
          <br />
          <br />
          <strong>Lorem, ipsum dolor</strong> sit amet consectetur adipisicing
          elit. Eligendi voluptate quo, consectetur adipisci voluptas pariatur
          quaerat. Sed quaerat itaque praesentium! Laudantium consequatur, est
          autem voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
      </LegalContainer>
      <MissionContainer id="mission">
        <Title whiteBg={true}>Our Mission</Title>
        <hr />
        <Paragraph whiteBg={true}>
          <strong>Lorem, ipsum dolor </strong> sit amet consectetur adipisicing
          elit. Eligendi voluptate quo, consectetur adipisci voluptas pariatur
          quaerat. Sed quaerat itaque praesentium! Laudantium consequatur, est
          autem voluptatum excepturi voluptatem asperiores at natus!
          <br />
          <br />
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
      </MissionContainer>
    </>
  );
}

export default LegalMs;
