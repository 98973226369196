import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import {
  Container,
  ContainerColumn1,
  ContainerGrid,
  ContainerTitle,
  ContainerWrapper,
  HomeButton,
  HomeButtonArea,
  TextArea,
  TextParagraph,
  TextTitle,
} from '../components/ConditionsElem';
import { TermsOfUseEn } from '../components/LegalData/Data';

function TermsPage() {
  return (
    <Container id="tou">
      <HomeButtonArea>
        <FaArrowLeft />
        <HomeButton to="/">Back to home page</HomeButton>
      </HomeButtonArea>
      <ContainerWrapper>
        <ContainerTitle>Terms of use</ContainerTitle>
        <ContainerGrid>
          <ContainerColumn1>
            <TextArea>
              <i>[EN]</i>
              {TermsOfUseEn.map((item) => (
                <>
                  <TextTitle key={item._id}>
                    {item._id}.{item.title}
                  </TextTitle>
                  <TextParagraph>{item.text}</TextParagraph>
                </>
              ))}
            </TextArea>
          </ContainerColumn1>
        </ContainerGrid>
      </ContainerWrapper>
    </Container>
  );
}

export default TermsPage;
