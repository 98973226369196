export const TermsOfUseEn = [
  {
    _id: 1,
    title: 'AGREEMENT TO TERMS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 2,
    title: 'INTELLECTUAL PROPERTY RIGHTS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 3,
    title: 'USER REPRESENTATIONS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 4,
    title: 'PROHIBITED ACTIVITIES',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 5,
    title: 'USER GENERATED CONTRIBUTIONS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 6,
    title: 'CONTRIBUTION LICENSE',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 7,
    title: 'SUBMISSIONS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 8,
    title: 'SITE MANAGEMENT',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 9,
    title: 'PRIVACY POLICY AND DATA PROTECTION',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 10,
    title: 'TERM AND TERMINATION',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 11,
    title: 'MODIFICATIONS AND INTERRUPTIONS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 12,
    title: 'GOVERNING LAW',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 13,
    title: 'DISPUTE RESOLUTION',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 14,
    title: 'CORRECTIONS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 15,
    title: 'DISCLAIMER',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 16,
    title: 'INDEMNIFICATION',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 17,
    title: 'USER DATA',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 18,
    title: 'MISCELLANEOUS',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 19,
    title: 'CONTACT US',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
];

export const TermsConditionEn = [
  {
    _id: 1,
    title: 'General conditions',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 2,
    title: 'Assent & Acceptance',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 3,
    title: 'Applying for job via website or direct by email',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 4,
    title: 'Recruitment Policy and Applicants',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 5,
    title: 'Marlins English Language Testing',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 6,
    title: 'Changes',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 7,
    title: 'NO WARRANTIES',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 8,
    title: 'LIMITATION OF LIABILITY',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 9,
    title: 'INDEMNIFICATION',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 10,
    title: 'USER DATA',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 11,
    title: 'TERMINATION',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 12,
    title: 'DISPUTES',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
];

export const PrivacyPolicyEn = [
  {
    _id: 1,
    title: 'Data Controller',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 2,
    title: 'Data Privacy',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 3,
    title: 'Data collection purpose:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 4,
    title: 'Data Access',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 5,
    title: 'How we collect your data:',
    text: ` Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 6,
    title: 'How we use your data:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 7,
    title: 'How do we store your data?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 8,
    title: 'What are your data protection rights?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 9,
    title: 'Disclosure of personal information:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 10,
    title: 'What are cookies?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 11,
    title: 'How do we use cookies?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 12,
    title: 'What types of cookies do we use?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 13,
    title: 'How Does Google Analytics Collect Data?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 14,
    title: 'How to manage cookies?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 15,
    title: 'Changes to our privacy policy:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 16,
    title: 'How to contact us:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 17,
    title: 'Grievance Procedure:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 18,
    title: 'How to contact the appropriate authority:',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
];

export const CookiePolicyEn = [
  {
    _id: 1,
    title: 'What are cookies?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 2,
    title: 'How do we use cookies?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 3,
    title: 'What types of cookies do we use?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
  {
    _id: 4,
    title: 'How to manage cookies?',
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus! Eligendi
    voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
    quaerat itaque praesentium! Laudantium consequatur, est autem
    voluptatum excepturi voluptatem asperiores at natus!`,
  },
];
