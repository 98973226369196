import React, { useEffect, useState } from 'react';
import { FaBars, FaCaretDown } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import logoW from '../../images/LogoW.png';
import {
  DropdownItemsOne,
  DropdownItemsThree,
  DropdownItemsTwo,
} from './DropdownData';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLinksD,
  NavLogo,
  NavLogoWrapper,
  NavMenu,
} from './NavbarElements';

function Navbar({ toggle }) {
  const [scrollNav, setScrollNav] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const hoverIn = () => {
    setDropdown(true);
  };
  const hoverIn2 = () => {
    setDropdown2(true);
  };
  const hoverIn3 = () => {
    setDropdown3(true);
  };
  const hoverOut = () => {
    setDropdown(false);
    setDropdown2(false);
    setDropdown3(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogoWrapper onClick={toggleHome}>
            <NavLogo src={logoW} alt="Peakweb Template" />
          </NavLogoWrapper>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>

          <NavMenu>
            <NavItem onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
              <NavLinks
                to="hero"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                className="dropdownLink"
              >
                Home <FaCaretDown />
              </NavLinks>
              {dropdown && (
                <NavMenu
                  className={
                    dropdown ? 'dropdown-menu hovered' : 'dropdown-menu  '
                  }
                >
                  {DropdownItemsOne.map((item, index) => {
                    return (
                      <li key={index}>
                        <NavLinksD
                          to={item.path}
                          smooth={true}
                          duration={500}
                          spy={true}
                          exact="true"
                          offset={-80}
                          className={item.cName}
                        >
                          {item.text}
                        </NavLinksD>
                      </li>
                    );
                  })}
                </NavMenu>
              )}
            </NavItem>
            <NavItem onMouseEnter={hoverIn2} onMouseLeave={hoverOut}>
              <NavLinks
                to="recruit"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                className="dropdownLink"
              >
                Recruit <FaCaretDown />
              </NavLinks>
              {dropdown2 && (
                <NavMenu
                  className={
                    dropdown ? 'dropdown-menu hovered' : 'dropdown-menu  '
                  }
                >
                  {DropdownItemsTwo.map((item, index) => {
                    return (
                      <li key={index}>
                        <NavLinksD
                          to={item.path}
                          smooth={true}
                          duration={500}
                          spy={true}
                          exact="true"
                          offset={-80}
                          className={item.cName}
                        >
                          {item.text}
                        </NavLinksD>
                      </li>
                    );
                  })}
                </NavMenu>
              )}
            </NavItem>

            <NavItem onMouseEnter={hoverIn3} onMouseLeave={hoverOut}>
              <NavLinks
                to="bc"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                className="dropdownLink"
              >
                Business Consulting <FaCaretDown />
              </NavLinks>
              {dropdown3 && (
                <NavMenu
                  className={
                    dropdown ? 'dropdown-menu hovered' : 'dropdown-menu  '
                  }
                >
                  {DropdownItemsThree.map((item, index) => {
                    return (
                      <li key={index}>
                        <NavLinksD
                          to={item.path}
                          smooth={true}
                          duration={500}
                          spy={true}
                          exact="true"
                          offset={-80}
                          className={item.cName}
                        >
                          {item.text}
                        </NavLinksD>
                      </li>
                    );
                  })}
                </NavMenu>
              )}
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                className="link"
              >
                Contact
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
