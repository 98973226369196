import React from 'react';
import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroText,
  HeroTitle,
  VideoBg,
} from './HeroElem';
import Video from '../../videos/Princess3.mp4';
function Hero() {
  return (
    <HeroContainer id="hero">
      <HeroBg>
        <VideoBg
          autoPlay={true}
          loop={true}
          controls={false}
          playsInline
          muted
          src={Video}
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        <HeroTitle>Choose us, it's win-win!</HeroTitle>
        <HeroText>
          Our job is to make sure that we build a better world, making progress,
          and giving a source of inspiration and vision for the future!
        </HeroText>
      </HeroContent>
    </HeroContainer>
  );
}

export default Hero;
