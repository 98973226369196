import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  padding-top: 80px;
  background-color: #16243f;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
    height: auto;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  @media screen and (max-width: 575.98px) {
    padding: 2rem;
  }
`;
export const ContainerTitle = styled.h1`
  color: #fff;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 40px;
`;
export const ContainerGrid = styled.div`
  display: grid;
  grid-auto-columns: minmax() (auto, 1fr);
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1''col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const ContainerColumn1 = styled.div`
  grid-area: col1;
  padding: 0 15px;
`;
export const ContainerColumn2 = styled.div`
  grid-area: col2;
  padding: 0 15px;
`;
export const TextArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const TextTitle = styled.h2`
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  width: 100%;
  color: #0261a0;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const TextParagraph = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 2rem;
  color: #fff;
  text-align: justify;
  width: 100%;
`;

export const HomeButtonArea = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 30px;
  transition: all 0.7s ease-in-out;
  margin-top: 20px;
  &:hover {
    color: #0261a0;
  }
  & > * {
    margin-right: 10px;
  }

  @media screen and (max-width: 575.98px) {
    justify-content: center;
  }
`;
export const HomeButton = styled(Link)`
  text-decoration: none;
  font-size: 30px;
  color: #fff;
  border-bottom: 3px solid #fff;
  transition: all 0.7s ease-in-out;

  &:hover {
    color: #0261a0;
    border-bottom-color: #0261a0;
  }
`;
