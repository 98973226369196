import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import {
  Container,
  ContainerColumn1,
  ContainerGrid,
  ContainerTitle,
  ContainerWrapper,
  HomeButton,
  HomeButtonArea,
  TextArea,
  TextParagraph,
  TextTitle,
} from '../components/ConditionsElem';
import { TermsConditionEn } from '../components/LegalData/Data';

function TcsPage() {
  return (
    <Container id="tcs">
      <HomeButtonArea>
        <FaArrowLeft />
        <HomeButton to="/">Back to home page</HomeButton>
      </HomeButtonArea>
      <ContainerWrapper>
        <ContainerTitle>terms and conditions of services</ContainerTitle>
        <ContainerGrid>
          <ContainerColumn1>
            <TextArea>
              <i>[EN]</i>
              <TextParagraph>
                This legal notice applies to the entire contents of this website
                under the domain name www.cardinal-shipping.com (‘website’) and
                to any correspondence by email between us and you. Please read
                these terms carefully before using this website. Using this
                website indicates that you accept these terms regardless of
                whether or choose us or not. If you do not accept these terms,
                do not use this website. This notice is issued by Cardinal
                Shipping Services SRL, Reg. No. J13/1409/2000, Romanian ID:
                13300220, EUID: RO41807777, with registered address in Constanta
                City, 36, Miron Costin Street, 900682, Romania.
              </TextParagraph>
              {TermsConditionEn.map((item) => (
                <>
                  <TextTitle>{item.title}</TextTitle>
                  <TextParagraph>{item.text}</TextParagraph>
                </>
              ))}
              <TextParagraph>
                <strong>
                  These Terms and Conditions for provided services constitute
                  the entire agreement and understanding between you and us.
                </strong>
              </TextParagraph>
            </TextArea>
          </ContainerColumn1>
        </ContainerGrid>
      </ContainerWrapper>
    </Container>
  );
}

export default TcsPage;
