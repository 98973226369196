import React from 'react';
import styled from 'styled-components/macro';
import {
  Column1,
  Column2,
  GridDiv,
  GridImage,
  GridText,
  Paragraph,
  Title,
} from '../SectionsElem';
import img from '../../images/p1.jpg';
import DividerW from '../DividerW';

export const RecruitContainer = styled.div`
  width: 100%;
  background-color: #16243f;
`;

export const RecruitWrapper = styled.div`
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ imgStart }) => (imgStart ? 'flex-start' : 'center')};
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  height: 100%;
  width: 100%;
`;
function Recruit() {
  return (
    <>
      <RecruitContainer id="recruit">
        <RecruitWrapper>
          <Title>e-Recruitment</Title>
          <hr />
          <GridDiv imgStart={true}>
            <Column1>
              <GridText>
                <Paragraph>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi voluptate quo, consectetur adipisci voluptas pariatur
                  quaerat. Sed quaerat itaque praesentium! Laudantium
                  consequatur, est autem voluptatum excepturi voluptatem
                  asperiores at natus! Lorem, ipsum dolor sit amet consectetur
                  adipisicing elit. Eligendi voluptate quo, consectetur adipisci
                  voluptas pariatur quaerat. Sed quaerat itaque praesentium!
                  Laudantium consequatur, est autem voluptatum excepturi
                  voluptatem asperiores at natus!
                </Paragraph>
                <Paragraph>
                  <strong>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  </strong>
                  <br />
                  Eligendi voluptate quo, consectetur adipisci voluptas pariatur
                  quaerat.
                  <br /> Sed quaerat itaque praesentium!
                  <br /> Laudantium consequatur, est autem voluptatum excepturi
                  voluptatem asperiores at natus!
                  <br /> Lorem, ipsum dolor sit amet consectetur adipisicing
                  elit. Eligendi voluptate quo, consectetur adipisci voluptas
                  pariatur quaerat.
                </Paragraph>
                <Paragraph>
                  <strong>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Eligendi voluptate quo, consectetur adipisci voluptas
                    pariatur quaerat. Sed quaerat itaque praesentium!
                  </strong>
                </Paragraph>
              </GridText>
            </Column1>
            <Column2>
              <ImageContainer imgStart={true}>
                <ImageWrapper>
                  <GridImage src={img} alt="Cardinal Shipping Services" />
                </ImageWrapper>
              </ImageContainer>
            </Column2>
          </GridDiv>
        </RecruitWrapper>
        <DividerW />
      </RecruitContainer>
    </>
  );
}

export default Recruit;
