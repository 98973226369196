import React, { useEffect } from 'react';
import img from '../../images/p1.jpg';
import styled from 'styled-components/macro';
import {
  Column1,
  Column2,
  GridDiv,
  GridImage,
  GridImageWrapper,
  GridText,
  Paragraph,
  Title,
} from '../SectionsElem';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const AboutContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #16243f;
  overflow: hidden;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

function About() {
  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 576,
    });
  }, []);
  return (
    <AboutContainer id="about">
      <Title>About Us</Title>
      <hr />
      <GridDiv>
        <Column1
          data-aos="fade-up-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          <GridText>
            <Paragraph>
              <strong>Lorem ipsum dolor</strong> sit amet consectetur
              adipisicing elit. Obcaecati modi quis vero cupiditate ex aliquam
              similique culpa facere nesciunt dolore quod, nisi minima rerum
              neque mollitia ratione aspernatur quidem nihil!
            </Paragraph>
            <Paragraph>
              <strong>Lorem ipsum dolor</strong> sit amet consectetur
              adipisicing elit. Obcaecati modi quis vero cupiditate ex aliquam
              similique culpa facere nesciunt dolore quod, nisi minima rerum
              neque mollitia ratione aspernatur quidem nihil!
            </Paragraph>
            <Paragraph>
              <strong>Lorem ipsum dolor</strong> sit amet consectetur
              adipisicing elit. Obcaecati modi quis vero cupiditate ex aliquam
              similique culpa facere nesciunt dolore quod, nisi minima rerum
              neque mollitia ratione aspernatur quidem nihil!
            </Paragraph>
            <Paragraph>
              <strong>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi voluptate quo, consectetur adipisci voluptas pariatur
                quaerat. Sed quaerat itaque praesentium! Laudantium consequatur,
                est autem voluptatum excepturi voluptatem asperiores at natus!
                Eligendi voluptate quo, consectetur adipisci voluptas pariatur
                quaerat. Sed quaerat itaque praesentium! Laudantium consequatur,
                est autem voluptatum excepturi voluptatem asperiores at natus!
              </strong>
            </Paragraph>
          </GridText>
        </Column1>
        <Column2
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
        >
          <GridImageWrapper>
            <GridImage src={img} alt="Cardinal Shipping Services" />
          </GridImageWrapper>
        </Column2>
      </GridDiv>
      <Paragraph
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-delay="200"
      >
        <strong>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </strong>
      </Paragraph>
      <Paragraph
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-delay="200"
      >
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
        voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
        quaerat itaque praesentium! Laudantium consequatur, est autem voluptatum
        excepturi voluptatem asperiores at natus!{' '}
        <strong>
          Sed quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </strong>{' '}
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
        voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
        quaerat itaque praesentium! Laudantium consequatur, est autem voluptatum
        excepturi voluptatem asperiores at natus!
      </Paragraph>
    </AboutContainer>
  );
}

export default About;
