import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const ApplyContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #16243f;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const FormArea = styled.form`
  max-width: 1100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;
export const FormLabel = styled.label`
  font-size: 20px;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  margin-top: 20px;
`;

export const FormInput = styled.input`
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: none;
  border-bottom: 3px solid #fff; /* Gray border */
  background: transparent;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  color: white;
`;

export const FormTextArea = styled.textarea`
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: none;
  border-bottom: 3px solid #fff; /* Gray border */
  background: transparent;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  color: white;
`;
export const FormButtonArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
`;
export const FromButton = styled.button`
  width: 20%;
  font-size: 1.5rem;
  letter-spacing: 2px;
  color: #fff;
  padding: 10px 20px;
  border: 3px solid #0261a0;
  border-radius: 10px;
  text-transform: uppercase;

  background-color: #fff;

  background-color: transparent;
  transition: all 0.6s ease-out;

  &:hover {
    border: 3px dashed #fff;
    background-color: transparent;
    transition: all 0.6s ease-out;
  }

  @media screen and (max-width: 576.98px) {
    width: 50%;
    padding: 10px 25px;

    font-size: 2rem;
  }
`;

export const InputLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.8s ease-out;

  &:hover {
    color: #00b7ff;
  }
`;
