import styled from 'styled-components/macro';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const FooterContainer = styled.footer`
  background-color: #16243f;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1500px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  color: #fff;
  width: 200px;
  text-align: center;

  @media screen and (max-width: 576.98px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const FooterLinkR = styled(LinkR)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  transition: color 0.3s ease-out;
  width: 100%;
  &:hover {
    color: #ff9d00;
    transition: color 0.3s ease-out;
  }
`;
export const FooterLinkS = styled(LinkS)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  transition: color 0.3s ease-out;
  width: 100%;
  &:hover {
    color: #ff9d00;
    transition: color 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1100px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogoWrapper = styled.div`
  max-width: 100px;
  height: 100%;
  width: 100%;
`;

export const SocialLogo = styled.img`
  height: 100%;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;

  & > * {
    margin-right: 10px;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
`;

export const SocialIconsLink = styled(LinkR)`
  color: #fff;
  font-size: 24px;
`;
