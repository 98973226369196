import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import img1 from '../../images/p1.jpg';
import img2 from '../../images/p1.jpg';
import {
  Column1,
  Column2,
  GridDiv,
  GridImage,
  GridImageWrapper,
  GridText,
  Paragraph,
  Title,
} from '../SectionsElem';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const HistoryContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

function HistoryArea() {
  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 576,
    });
  }, []);
  return (
    <HistoryContainer id="history">
      <Title whiteBg={true}>History</Title>
      <hr />
      <GridText>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
      </GridText>
      <GridDiv imgStart={true}>
        <Column1>
          <GridText>
            <Paragraph whiteBg={true}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
              voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
              quaerat itaque praesentium! Laudantium consequatur, est autem
              voluptatum excepturi voluptatem asperiores at natus! Eligendi
              voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
              quaerat itaque praesentium! Laudantium consequatur, est autem
              voluptatum excepturi voluptatem asperiores at natus!
            </Paragraph>
            <Paragraph whiteBg={true}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
              voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
              quaerat itaque praesentium! Laudantium consequatur, est autem
              voluptatum excepturi voluptatem asperiores at natus! Eligendi
              voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
              quaerat itaque praesentium! Laudantium consequatur, est autem
              voluptatum excepturi voluptatem asperiores at natus!
            </Paragraph>
          </GridText>
        </Column1>
        <Column2
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          <GridImageWrapper imgStart="true">
            <GridImage src={img1} alt="Cardinal Shipping Services" />
          </GridImageWrapper>
        </Column2>
      </GridDiv>
      <GridDiv imgstart={false}>
        <Column1>
          <GridText>
            <Paragraph whiteBg={true}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
              voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
              quaerat itaque praesentium! Laudantium consequatur, est autem
              voluptatum excepturi voluptatem asperiores at natus! Eligendi
              voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
              quaerat itaque praesentium! Laudantium consequatur, est autem
              voluptatum excepturi voluptatem asperiores at natus!
            </Paragraph>
          </GridText>
        </Column1>
        <Column2
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          <GridImageWrapper>
            <GridImage src={img2} alt="Cardinal Shipping Services" />
          </GridImageWrapper>
        </Column2>
      </GridDiv>
      <GridText>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <Paragraph whiteBg={true}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
      </GridText>
    </HistoryContainer>
  );
}

export default HistoryArea;
