import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Column1,
  Column2,
  GridDiv,
  GridText,
  Paragraph,
  Title,
} from '../SectionsElem';
import imgSorina from '../../images/m1.png';
import imgAlexandra from '../../images/m1.png';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const TmContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #16243f;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ imgStart }) => (imgStart ? 'flex-start' : 'flex-end')};
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  height: 100%;
  width: 100%;
`;

export const TmImage = styled.img`
  width: 100%;
  border-radius: 30px;
  box-shadow: 5px 8px 25px rgba(255, 255, 255, 0.4);
`;
export const ListArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  li {
    width: 80%;
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
  }
  @media screen and (max-width: 575.98px) {
    flex-direction: column;
    ul {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
    }
    li {
      font-size: 15px;
    }
  }
`;

function TeamManagement() {
  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 576,
    });
  }, []);
  return (
    <TmContainer id="tm">
      <Title>team management</Title>
      <hr />
      <br />
      <GridDiv imgStart={true}>
        <Column1>
          <GridText>
            <h2>Lorem Ipsum</h2>
            <h3>
              <strong>
                <i>Insert job titles</i>
              </strong>
            </h3>
            <br />
            <Paragraph>
              <strong>Lorem, ipsum dolor </strong> sit amet consectetur
              adipisicing elit. Eligendi voluptate quo, consectetur adipisci
              voluptas pariatur quaerat. Sed quaerat itaque praesentium!
              Laudantium consequatur, est autem voluptatum excepturi voluptatem
              asperiores at natus!{' '}
              <strong>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </strong>{' '}
              Eligendi voluptate quo, consectetur adipisci voluptas pariatur
              quaerat.
              <strong>
                {' '}
                Sed quaerat itaque praesentium! Laudantium consequatur, est
                autem voluptatum excepturi voluptatem asperiores at natus!
              </strong>{' '}
              Bachelor's Degree of Economics Engineering and Management, and
              Master's Degree of Maritime Law. Knowledge, skills, abilities,
              self-image, traits, mindsets, feelings, and ways of thinking
              achieved a high performance that created reasonable impact on
              business outcomes, her competency comprising in:
            </Paragraph>
            <ListArea>
              <ul>
                <li>Human Resource Expertise</li>
                <li>Relationship Management</li>
                <li>Consultation</li>
                <li>Leadership in Crew Management and Staff</li>
                <li>Communication</li>
              </ul>
              <ul>
                <li>Global and Cultural Effectiveness</li>
                <li>Ethical Practice</li>
                <li>Critical Evaluation</li>
                <li>Business Acumen</li>
              </ul>
            </ListArea>
          </GridText>
        </Column1>
        <Column2
          data-aos="zoom-out"
          data-aos-duration="2000"
          data-aos-delay="100"
        >
          <ImageWrapper imgStart={true}>
            <TmImage
              src={imgSorina}
              alt="Sorina Adam Cardinal Shipping Services"
            />
          </ImageWrapper>
        </Column2>
      </GridDiv>
      <br />
      <GridDiv imgStart={false}>
        <Column1>
          <GridText>
            <h2>Lorem Ipsum</h2>
            <h3>
              <strong>
                <i>Insert job titles</i>
              </strong>
            </h3>
            <br />
            <Paragraph>
              <strong>Lorem, ipsum dolor </strong> sit amet consectetur
              adipisicing elit. Eligendi voluptate quo, consectetur adipisci
              voluptas pariatur quaerat. Sed quaerat itaque praesentium!
              Laudantium consequatur, est autem voluptatum excepturi voluptatem
              asperiores at natus!{' '}
              <strong>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </strong>{' '}
              Eligendi voluptate quo, consectetur adipisci voluptas pariatur
              quaerat.
              <strong>
                {' '}
                Sed quaerat itaque praesentium! Laudantium consequatur, est
                autem voluptatum excepturi voluptatem asperiores at natus!
              </strong>{' '}
              Bachelor's Degree of Economics Engineering and Management, and
              Master's Degree of Maritime Law. Knowledge, skills, abilities,
              self-image, traits, mindsets, feelings, and ways of thinking
              achieved a high performance that created reasonable impact on
              business outcomes, her competency comprising in:
            </Paragraph>
            <ListArea>
              <ul>
                <li>Human Resource Expertise</li>
                <li>Relationship Management</li>
                <li>Consultation</li>
                <li>Leadership in Crew Management and Staff</li>
                <li>Communication</li>
              </ul>
              <ul>
                <li>Global and Cultural Effectiveness</li>
                <li>Ethical Practice</li>
                <li>Critical Evaluation</li>
                <li>Business Acumen</li>
              </ul>
            </ListArea>
          </GridText>
        </Column1>
        <Column2
          data-aos="zoom-out"
          data-aos-duration="2000"
          data-aos-delay="500"
        >
          <ImageContainer imgStart={false}>
            <ImageWrapper>
              <TmImage
                src={imgAlexandra}
                alt="Alexandra Matei Cardinal Shipping Services"
              />
            </ImageWrapper>
          </ImageContainer>
        </Column2>
      </GridDiv>
    </TmContainer>
  );
}

export default TeamManagement;
