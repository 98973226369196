import React from 'react';
import styled from 'styled-components/macro';
import { Column1, Column2, GridDiv, Title } from '../SectionsElem';

export const ContactContainer = styled.div`
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  @media screen and (max-width: 575.98px) {
    padding: 3rem;
  }
`;

export const TextArea = styled.div`
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #16243f;
`;

function ContactArea() {
  return (
    <ContactContainer id="contact">
      <Title whiteBg={true} className="centered">
        contact us
      </Title>
      <hr />
      <br />
      <br />
      <GridDiv>
        <Column1>
          <TextArea>
            <h2>Mailing Address</h2>
            <ul>
              <li>Enter business name S.R.L</li>
              <li>Enter Address</li>
              <li>Enter town, Country</li>
            </ul>
          </TextArea>
        </Column1>
        <Column2>
          <TextArea>
            <h2>Contact Details</h2>
            <ul>
              <li>
                E-mail: <a href="mailto:enteremail">Enter Email</a>
              </li>
              <li>
                Telephone: <a href="tel:phone">Enter Phone</a>
              </li>
            </ul>
          </TextArea>
        </Column2>
      </GridDiv>
    </ContactContainer>
  );
}

export default ContactArea;
