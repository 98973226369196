import React from 'react';
import About from '../components/About/About';
import ApplyForm from '../components/Apply/Apply';
import ContactArea from '../components/Contact/Contact';
import Divider from '../components/Divider';
import DividerTop from '../components/DividerTop';
import DividerW from '../components/DividerW';
import Hero from '../components/Hero/Hero';
import HistoryArea from '../components/History/History';
import LegalMs from '../components/Legal/Legal';
import Recruit from '../components/Recruit/Recruit';
import TeamManagement from '../components/Tm/Tm';

function HomePage() {
  return (
    <>
      <Hero />
      {/* About */}
      <About />
      {/* History */}
      <DividerW />
      <HistoryArea />
      <Divider />
      {/* Team Management */}
      <TeamManagement />
      {/* Legal structure, our mission */}
      <DividerTop />
      <LegalMs />
      <Divider />
      {/* e-recruit */}

      <Recruit />

      <Divider />
      {/* Apply for a job */}
      <ApplyForm />

      {/* career opportunities, consultancy */}

      <DividerW />
      {/* contact us */}
      <ContactArea />
      <Divider />
    </>
  );
}

export default HomePage;
