import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { GridText, Paragraph, TextItems, Title } from '../SectionsElem';
import { BsCloudDownload } from 'react-icons/bs';
import {
  ApplyContainer,
  FormArea,
  FormButtonArea,
  FormInput,
  FormLabel,
  FormWrapper,
  FromButton,
  InputLink,
} from './ApplyElem';

function ApplyForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    let text = document.getElementById('submitLabel');

    emailjs
      .sendForm(
        'service_css',
        'template_css',
        form.current,
        'user_3d8YulZHmk6sl3phn2SFu'
      )
      .then(
        (result) => {
          console.log(result.text);
          document.getElementById('form').reset();
          text.innerText = 'Message has been sent';
        },
        (error) => {
          console.log(error.text);
          text.innerText = error.text;
        }
      );
  };

  return (
    <ApplyContainer id="apply">
      <Title>Applying for a job</Title>
      <hr />
      <GridText>
        <Paragraph>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus! Eligendi
          voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
          quaerat itaque praesentium! Laudantium consequatur, est autem
          voluptatum excepturi voluptatem asperiores at natus!
        </Paragraph>
        <TextItems>
          <li>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus! Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus!
          </li>
          <li>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus!
          </li>
          <li>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus!
          </li>
          <li>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus!
          </li>
          <li>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus! Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus!
          </li>
          <li>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            voluptate quo, consectetur adipisci voluptas pariatur quaerat. Sed
            quaerat itaque praesentium! Laudantium consequatur, est autem
            voluptatum excepturi voluptatem asperiores at natus!
          </li>
        </TextItems>
      </GridText>

      <FormWrapper>
        <div className="download">
          <a href="#file" download="file">
            Download our CV model here{' '}
            <BsCloudDownload className="iconDownload" />
          </a>
        </div>
        <FormArea id="form" ref={form} onSubmit={sendEmail}>
          <FormLabel>First Name</FormLabel>
          <FormInput
            name="name"
            type="text"
            placeholder="Enter your first name"
            required
          />
          <FormLabel>Last Name</FormLabel>
          <FormInput
            name="surname"
            type="text"
            placeholder="Enter your last name"
            required
          />
          <FormLabel>Email</FormLabel>
          <FormInput
            name="email"
            type="email"
            placeholder="Enter your email"
            required
          />
          <FormLabel>Message (Optional)</FormLabel>
          <FormInput
            name="message"
            type="text"
            placeholder="Message us with details if any visa, or valid STCW"
          />
          <FormLabel htmlFor="cv" className="uploadButton">
            Attach CV
          </FormLabel>
          <FormInput name="cv" type="file" id="cv" multiple />
          <div id="tc">
            <input type="checkbox" id="tc" name="tc" required />
            <label htmlFor="tc">
              I agree with the{' '}
              <InputLink to="/terms">terms and condition</InputLink>
            </label>
          </div>
          <div id="gdpr">
            <input type="checkbox" id="gdpr" name="gdpr" required />
            <label htmlFor="gdpr">
              I acknowledge and consent to data processing as depicted in{' '}
              <InputLink to="/gdpr">gdpr</InputLink>
            </label>
          </div>
          <div id="cookie">
            <input type="checkbox" id="cookie" name="cookie" required />
            <label htmlFor="cookie">
              I agree with the{' '}
              <InputLink to="/cookies">cookies policy</InputLink>
            </label>
          </div>
          <FormLabel id="submitLabel"></FormLabel>
          <FormButtonArea>
            <FromButton type="submit">Send</FromButton>
          </FormButtonArea>
        </FormArea>
      </FormWrapper>
    </ApplyContainer>
  );
}

export default ApplyForm;
