import styled from 'styled-components/macro';

export const Title = styled.h1`
  font-size: 40px;
  text-transform: uppercase;
  width: 100%;
  color: ${({ whiteBg }) => (whiteBg ? '#0261a0' : '#fff')};
  &.centered {
    text-align: center;
  }
  @media screen and (max-width: 575.98px) {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }
`;

export const GridDiv = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1''col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  grid-area: col1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 575.98px) {
    margin-bottom: 30px;
  }
`;

export const Column2 = styled.div`
  grid-area: col2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  @media screen and (max-width: 575.98px) {
    margin-bottom: 30px;
  }
`;

export const GridText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

export const Paragraph = styled.p`
  width: 100%;
  font-size: 25px;
  color: ${({ whiteBg }) => (whiteBg ? '#16243f' : '#fff')};
  font-weight: 200;
  margin: 0;
  margin-bottom: 20px;
  line-height: 2.5rem;
  text-align: justify;
  @media screen and (max-width: 575.98px) {
    font-size: 15px;
  }
`;

export const GridImageWrapper = styled.div`
  max-width: 750px;
  height: 100%;
  margin-left: ${({ imgStart }) => (imgStart ? '0' : '5rem')};
  @media screen and (max-width: 575.98px) {
    margin: 0;
  }
`;

export const GridImage = styled.img`
  width: 100%;
  border-radius: 16px;
`;
export const TextItems = styled.ul`
  width: 100%;
  color: ${({ whiteBg }) => (whiteBg ? '#16243f' : '#fff')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: 30px;
  font-size: 25px;
  & > * {
    margin-bottom: 20px;
  }
`;

export const TextItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  font-size: 25px;
`;
