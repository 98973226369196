import React from 'react';
import logo from '../../images/LogoW.png';
import { FaCopyright } from 'react-icons/fa';
import {
  FooterContainer,
  FooterLinkR,
  FooterLinkS,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterWrap,
  SocialLogo,
  SocialLogoWrapper,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from './FooterElements';

function Footer() {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Quick Menu</FooterLinkTitle>
              <FooterLinkS
                to="legal"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Legal structure
              </FooterLinkS>
              <FooterLinkS
                to="apply"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Applying for a job
              </FooterLinkS>
              <FooterLinkS
                to="train"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Training
              </FooterLinkS>
            </FooterLinkItems>
          </FooterLinksWrapper>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Legal Documents</FooterLinkTitle>
              <FooterLinkR to="/termuse">Terms of Use</FooterLinkR>
              <FooterLinkR to="/termservices">
                Terms and Conditions of Services
              </FooterLinkR>
              <FooterLinkR to="/cookies">Cookies Policy</FooterLinkR>
              <FooterLinkR to="/gdpr">Our Privacy Policy</FooterLinkR>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights>
              <SocialLogoWrapper>
                <SocialLogo src={logo} alt="Cardinal Shipping Services" />
              </SocialLogoWrapper>
              <FaCopyright />
              {new Date().getFullYear()} All rights reserved
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
