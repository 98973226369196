export const DropdownItemsOne = [
  {
    text: 'About Us',
    path: 'about',
    cName: 'dropdown-item',
  },
  {
    text: 'History',
    path: 'history',
    cName: 'dropdown-item',
  },
  {
    text: 'Team Management',
    path: 'tm',
    cName: 'dropdown-item',
  },
  {
    text: 'Legal Structure',
    path: 'legal',
    cName: 'dropdown-item',
  },
  {
    text: 'Our Mission',
    path: 'mission',
    cName: 'dropdown-item',
  },
];
export const DropdownItemsTwo = [
  {
    text: 'Career Opportunities',
    path: 'career',
    cName: 'dropdown-item',
  },
  {
    text: 'Applying for a job',
    path: 'apply',
    cName: 'dropdown-item',
  },
  {
    text: 'Consultancy',
    path: 'consult',
    cName: 'dropdown-item',
  },
];
export const DropdownItemsThree = [
  {
    text: 'Management',
    path: 'management',
    cName: 'dropdown-item',
  },
  {
    text: 'HR consultant',
    path: 'hr',
    cName: 'dropdown-item',
  },
  {
    text: 'Training',
    path: 'train',
    cName: 'dropdown-item',
  },
];
